var Tivoli = Tivoli || {};

Tivoli.musicPlayer = function () {
	var $musicSection = $('.music-section')

	
    function init() {
		//Disabled until B&O fixes bug
		return;
		
        if ($musicSection.length <= 0) {
            return;
        }
		setupTracks();
    }

	function setupTracks() {
		var tracks = [];
		$('.mp3-audio').each(function (index, value) { 
			  tracks.push($(this).attr('data-mp3-track'));
		});

		current = 1;
		audio = $('audio');
		len = tracks.length -1;
		audio[0].src = tracks[0];
		audio[0].load();
		audio[0].play();
		audio[0].addEventListener('ended',function(e){
			if(current > len){
				current = 0;
				mp3 = tracks[0];
			}else{
				mp3 = tracks[current];    
			}
			current++;
			startPlayer(mp3,audio[0]);
		});
	}

	function startPlayer(mp3, player){
		player.src = mp3;      
        audio[0].load();
        audio[0].play();
	}

    $(document).ready(function () {
        init();
    });

    return {
    };
}();