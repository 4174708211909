var Tivoli = Tivoli || {};

Tivoli = (function ($) {
    var screenWidth = screen.width,
        screenHeight = screen.height,
        $window = $(window),
		$html = $('html');
        screenMaxWidth = Math.max(screenWidth, screenHeight);
        urlState = "";

    var settings = {
        width: {
            small: 641,
			littlemedium: 850,
            medium: 1025,
            large: 1320,
            xlarge: 1580
        },
		nav: {
			width: 256
		}
    };

    var vars = {
        mobileThreshold: settings.width.small, // if you change this, you must change mobile-landscape-width in _variables.scss
        tabletThreshold: settings.width.medium, // same as above
        screenWidth: screenWidth,
        screenHeight: screenHeight,
        screenMaxWidth: Math.max(screenWidth, screenHeight),
        windowWidth: $window.width(),
        windowHeight: $window.height(),
        devicePixelRatio: "devicePixelRatio" in window ? devicePixelRatio : 1,
        //devicePixelRatio: 1,
        scrollChecker: null,
        scrollTimeout: null,
        isTouch: Modernizr.touchevents,
        isMobileDevice: Modernizr.touchevents && screenMaxWidth < settings.width.small,
        isAnyTouchDevice: Modernizr.touchevents && screenMaxWidth < settings.width.medium,
		urlState: "",
		isIpad: navigator.userAgent.match(/iPad/i) != null
};

	var isBrowser = {
		IE: function() {
		    return navigator.userAgent.search("MSIE") >= 0 || navigator.userAgent.search("Trident") >= 0;
		},
		IE10: function() {
			return navigator.userAgent.search("MSIE") >= 0 && navigator.userAgent.search("MSIE 10.0");
		},
		Chrome: function() {
			return navigator.userAgent.search("Chrome") >= 0;
		},
		Firefox: function() {
			return navigator.userAgent.search("Firefox") >= 0;
		},
		Safari: function() {
			return navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0;
		},
		Opera: function() {
			return navigator.userAgent.search("Opera") >= 0;
		},
		IOS7: function() {
			return navigator.userAgent.search(/(iPad|iPhone);.*CPU.*OS 7_\d/i) >= 0;
		},
		iPhone: function() {
			return navigator.userAgent.match(/iPhone/i) != null;
		},
		iPad: function() {
			return navigator.userAgent.match(/iPad/i) != null;
		},
		Android: function() {
			return navigator.userAgent.toLowerCase().indexOf("android") > -1;
		},
		toString: function() {
			return navigator.userAgent;
		}
	};

	function browserDetectors() {
		if(isBrowser.IOS7()) {
			$html.addClass('IOS7');
		}
		if(isBrowser.Safari()) {
			$html.addClass('safari');
		}
		if(vars.isAnyTouchDevice) {
			$html.addClass('touch-device');
		}
		if(isBrowser.Android()) {
			$html.addClass('android');
		}
		if(isBrowser.iPad()) {
			$html.addClass('ipad');
		};
		if(isBrowser.iPhone()) {
			$html.addClass('iphone');
		}
		if(isBrowser.IE10()) {
			$html.addClass('lt-ie11');
		}
		if(isBrowser.IE()) {
			$html.addClass('ie-modern');
		}
	}

	function init() {

		
		browserDetectors();
		fontLoadTest();
		//FastClick.attach(document.body);
	}
    
	function getUrlParameterByNameInsensitive(name) {
	    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	    name = name.toLowerCase();
	    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search.toLowerCase());
	    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
	}

	function getUrlParameterByName(name) {
	    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
	    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
	}
    
    function getCurrentItemPath() {
        var itemPath = "/";

        if (TivoliData && TivoliData.itemPath) {
            itemPath = TivoliData.itemPath;
        }
        return itemPath;
    }
    
    function getCurrentLanguage() {
        var language = "en";
        if (TivoliData && TivoliData.language) {
            language = TivoliData.language;
        }
        return language;
    }
    
    function getGeneralErrorMessage() {
        var generalErrorMessage = "Error";

        if (TivoliData && TivoliData.generalErrorMessage) {
            generalErrorMessage = TivoliData.generalErrorMessage;
        }

        return generalErrorMessage;
    }
    
    String.prototype.endsWith = function (suffix) {
        return this.indexOf(suffix, this.length - suffix.length) !== -1;
    };

	function fontLoadTest() {
		WebFont.load({
			custom: {
				families: ['PT Serif', 'Prata']
			},
			active: function() {
				$(document).trigger('allFontsLoaded');
			}
		});
	}
    
	$(document).ready(function () {
	    init();
	});

	return {
		init : init,
		getUrlParameterByName: getUrlParameterByName,
		getUrlParameterByNameInsensitive: getUrlParameterByNameInsensitive,
		settings : settings,
		isBrowser: isBrowser,
		vars: vars,
		getCurrentItemPath: getCurrentItemPath,
		getCurrentLanguage: getCurrentLanguage,
		getGeneralErrorMessage: getGeneralErrorMessage
	};
})(jQuery);