var Tivoli = Tivoli || {};

Tivoli.navigation = function () {
	var $navSection = $('.navigation-box-section'),
		BoxesPerRow = $('.navigation-box-section .row:first-child .navigation-box').length,
		TotalBoxes = $('.navigation-box').length;
		PageLoaded = false;
	
    function init() {
        if ($navSection.length <= 0) {
            return;
        }
		setupNavigation();
    }

	function setupNavigation() {
		$.each($('.navigation-box'), function(key, value){
			$(this).attr('id', key);
		});

		$(document).keydown(function(e) {    
			e.preventDefault();
			switch(e.which) {        
				case 37: // left
					moveSelected(1);
					break;
				case 38: // up
					moveSelected(2);
					break;
				case 39: // right
					moveSelected(3);
					break;			
				case 40: // down
					moveSelected(4);
					break;
				case 13: // down
					loadPageContent();
					break;
				default: return;
				}
		});

		correctMissingButton();
	}

	function moveSelected(direction) {
		var cur_id = parseInt($('.selected').attr('id'));
		switch(direction){
			case 1://left
				selectNavigationBox(cur_id - 1);
				break;
			case 2://up
				selectNavigationBox(cur_id - BoxesPerRow);
				break;
			case 3://right
				selectNavigationBox(cur_id + 1);
				break;
			case 4://down
				selectNavigationBox(cur_id + BoxesPerRow);
				break;
		}
	}

	function selectNavigationBox(id) {
		if(id < (TotalBoxes) && id >= 0 && !PageLoaded) {
			$('.selected').removeClass('selected');  
			$('#'+id).addClass('selected'); 
		}
	}

	function loadPageContent() {
		var selectedId = $('.navigation-box.selected').attr('id');
		var selectedContent = $('[data-navigation-id="' + selectedId +'"]');
		if(!PageLoaded) {
			selectedContent.show();
			PageLoaded = true;
		} else {
			selectedContent.hide();
			PageLoaded = false;
		}
		
	}

	function correctMissingButton(){
		$.each($('.navigation-box-section .row'), function(key, value){
			if(BoxesPerRow != $(this).children('.navigation-box').length)
			{
				var difference = BoxesPerRow - $(this).children('.navigation-box').length;
				var marginLeft = 285;

				if(difference > 1){
					marginLeft = marginLeft + 10;
				}

				$(this).find('.navigation-box:first-child').css('margin-left', - marginLeft * difference + 'px')
			}
		});
	}

    $(document).ready(function () {
        init();
    });

    return {
    };
}();